'use client'
import {
  BiLogoWhatsapp,
  BiEnvelope,
  BiChat,
  BiCalendarAlt,
} from 'react-icons/bi';
import Link from 'next/link';
import cn from 'classnames';
import { setUrl } from '@utils/url';
import { GetStoreId } from '@services/mage/cms-helper';
import { GetStoreConfig } from '@services/config/client-helper';

export const ContactOptions = ({
  className,
  lang,
}: {
  className?: string;
  lang: string;
}) => {
  const storeId = GetStoreId(lang);
  const storeConfig = GetStoreConfig(storeId);
  const storeWhatsapp = storeConfig?.whatsapp ? storeConfig?.whatsapp.replace(/\s+/g, '').replace(/[-+]/g, '') : '';

  const handleClick = () => {
    if (typeof window !== 'undefined' && window.zE) {
      window.zE('webWidget', 'open');
    } else {
      console.warn('Zendesk widget is not loaded.');
    }
  };

  return (
    <div className={cn('px-6 lg:px-36 py-12 w-full bg-[#F0F4F7]', className)}>
      <div className="grid grid-flow-row grid-cols-10 text-brand-grey-0 gap-5 items-center">
        <div className="col-span-10 sm:col-span-2">
          <div className="flex flex-col items-center lg:items-start justify-center gap-1.5">
            <div className="font-cardo text-2xl">Need Help?</div>
            <div className="font-outfit text-[13px] lg:text-[17px]">
              We're here to help.
            </div>
          </div>
        </div>
        <div className="col-span-5 sm:col-span-2">
          <Link
            href={`https://api.whatsapp.com/send/?phone=${storeWhatsapp}`}
            target="_blank"
            rel="noopener noreferrer"
          >
            <div className="flex flex-col items-center justify-center gap-1">
              <BiLogoWhatsapp className="w-12 h-12 text-brand-grey-0" />
              <div className="font-outfit text-[13px] lg:text-[17px]">
                {storeConfig?.whatsapp}
              </div>
            </div>
          </Link>
        </div>
        <div className="col-span-5 sm:col-span-2">
          <a href="mailto:service@michaeltrio.com">
            <div className="flex flex-col items-center justify-center gap-1">
              <BiEnvelope className="w-12 h-12 text-brand-grey-0" />
              <div className="font-outfit text-[13px] lg:text-[17px]">
                service@michaeltrio.com
              </div>
            </div>
          </a>
        </div>
        <div className="col-span-5 sm:col-span-2">
          <div onClick={handleClick} className="cursor-pointer">
            <div className="flex flex-col items-center justify-center gap-1">
              <BiChat className="w-12 h-12 text-brand-grey-0" />
              <div className="font-outfit text-[13px] lg:text-[17px]">
                Live Chat
              </div>
            </div>
          </div>
        </div>
        <div className="col-span-5 sm:col-span-2">
          <Link href={setUrl('make-an-appointment', lang)}>
            <div className="flex flex-col items-center justify-center gap-1">
              <BiCalendarAlt className="w-12 h-12 text-brand-grey-0" />
              <div className="font-outfit text-[13px] lg:text-[17px]">
                Book An Appointment
              </div>
            </div>
          </Link>
        </div>
      </div>
    </div>
  );
};
